<template>
  <!-- 询价记录 -->
  <div :style="{height: height}" class="inquire-detail-query-box">
    <znl-table
      ref="table"
      v-loading="loading"
      :columns="columns"
      :drag-done="onDragDone"
      :header-menus="getResetTableHeaderMenu('StkInquireRecord')"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-index="pageIndex"
      :page-size="pageSize"
      :show-title-menus="true"
      :total-count="totalCount"
      box-class="inquire-detail-query-box"
      element-loading-text="数据加载中,请稍后..."
      gridtype="base"
      @dbclick="e => {$emit('dbl-click', e.rowData)}"
      @on-supplierName-click="onSupplierNameCellClick"
      @on-show-trigger="onHideShowColTrigger"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    ></znl-table>
    <stk-out-edit
      v-if="stkOutEditVisible"
      :editvisible="stkOutEditVisible"
      edit-type="edit"
      height="500px"
      @close="stkOutEditVisible=false"
      @confirm="stkOutEditVisible=false"
    ></stk-out-edit>
    <stk-in-edit
      v-if="stkInEditVisible"
      :editvisible="stkInEditVisible"
      edit-type="edit"
      height="500px"
      @close="stkInEditVisible=false"
      @confirm="stkInEditVisible=false"
    ></stk-in-edit>

    <!-- 生成采购订单 -->
    <ord-purchase-order-edit
      v-if="ordEditVisible"
      :edit-type="editType"
      :editvisible="ordEditVisible"
      :title="ordEditTitle"
      height="500px"
      @close="ordEditVisible=false"
      @confirm="ordEditVisible=false"
    ></ord-purchase-order-edit>
  </div>
</template>
<script>
const StkOutEdit = () =>
  import("@c_modules/Stk/StkOutEdit" /* webpackChunkName: "pages/stk" */);
const StkInEdit = () =>
  import("@c_modules/Stk/StkInEdit" /* webpackChunkName: "pages/stk" */);
import {erpTableSetHandler} from "@scripts/methods/common";
import {mixin as getCommonDataMixin} from "~assets/scripts/methods/getCommonData";
import OrdPurchaseOrderEdit from "@c_modules/Purchase/OrderEdit";

const CONFIG = {
  configURL: "StkInquireRecord/GetConfig",
  searchURL: "StkInquireRecord/Search",
  StockVMIAdd: "StockVMI/AddList",
  saveURL: "InquireAdopt/SaveByAdoptQty", // 按采纳量采纳
  saveSingleURL: "InquireAdopt/SaveByAdoptInfo" // 按单条询价采纳
};

export default {
  name: "InquireDetails",
  mixins: [erpTableSetHandler, getCommonDataMixin],
  config: CONFIG,
  components: {
    StkInEdit,
    StkOutEdit,
    OrdPurchaseOrderEdit
  },
  data() {
    return {
      editType: "edit",
      ordEditTitle: "新增采购单",
      BomAddTitle: "新增客户需求",
      ordEditVisible: false,
      resourceList: [],
      stkOutEditVisible: false,
      stkInEditVisible: false,
      bomMasterDetailEditVisible: false,
      columns: [],
      itemSource: [],
      searchFields: {},
      fieldsFilter: {},
      pageIndex: 1,
      totalCount: 0,
      pageSize: 30,
      isShow: false,
      InquireModel: "",
      RFQ_InquireModel: [],
      inputModel: "", // 需要查询的型号
      IQGUID: null, // 辅助字段
      loading: false,
      // 需采纳量
      needAdoptQty: 0,
      IsSingle: false,
      bomMasterDetailEditVisibleTwo: false,
      bomMasterDetail: [],
      // stkInquiry: {},
      stkInquiry: {
        // 询价参数传递
        SupplierName: "", // 供应商名称
        Model: "", // Model
        Brand: "",
        Packaging: "",
        MakeYear: "",
        Quality: "",
        DeliveryDate: "",
        InvQty: 0,
        Qty: 0,
        MPQ: "",
        Price: 0
      },
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  props: {
    formPage: {
      type: String,
      default: ""
    },
    bomQuoteType: {
      type: String,
      default: ""
    },
    purGrossRate: {
      type: Number,
      default: 0
    },
    initData: {
      type: Boolean,
      default: false
    },
    isAdopt: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    hasSearchCondition: {type: Boolean, default: false},
    isMultiRowsCheck: {type: Boolean, default: false},
    // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
    sourceType: {
      type: Number
    },
    // 需求来源GUID
    sourceGUID: {
      type: String
    },
    isOperate: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    isSupplierClick: true,
    isGeneratePurchaseOrder: false,
    height: {
      type: String,
      default: () => {
        return "100%";
      }
    },
    afterGridInit: {
      // 表格初始化完成后调用
      type: Function
    }
  },
  async created() {
  },
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function (config) {
      this.loading = true;
      config = await this.isSaveStorage("InquireDetails", CONFIG.configURL);
      // this.resourceList = config.ResourceList
      let tempConfig = [];
      if (this.hasRes("onAddStkOut")) {
        tempConfig.push({
          width: 30,
          type: "button",
          title: "出",
          btnTxt: "出",
          visible: true,
          isDeal: false,
          click: row => {
            this.onAddStkOut(row);
          }
        });
      }
      if (this.hasRes("onStkInadd")) {
        tempConfig.push({
          width: 30,
          type: "button",
          title: "入",
          btnTxt: "入",
          visible: true,
          isDeal: false,
          click: row => {
            this.onStkInadd(row);
          }
        });
      }

      let columns = config.ColumnConfigs;
      if (this.isSupplierClick) {
        _.extend(
          columns[
            _.findIndex(columns, item => item.BindField === "SupplierName")
            ],
          {
            event: "on-supplierName-click",
            className: "v-cell-link"
          }
        );
      }

      if (!this.IsMainAccount) {
        this.setHideColumns(columns);
      }

      // 生成采购订单按钮
      if (this.isGeneratePurchaseOrder) {
        columns.unshift({
          name: "Ordpurchase",
          binding: "Ordpurchase",
          width: 80,
          title: "生成采购单",
          type: "button",
          btnTxt: "生成采购单",
          visible: true,
          click: row => {
            this.onOrdpurchase(row);
          }
        });
      }

      if (this.isOperate) {
        this.columns = tempConfig.concat(columns);
      } else {
        this.columns = columns;
      }
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.$refs.table.init();
      this.afterGridInit && this.afterGridInit();
      if (this.initData) {
        await this.onBindData(1);
      }
      this.loading = false;
      if (this.formPage === 'stockManage') {
        this.columns = this.columns.filter(item => item.BindField !== 'AdoptQty')
      }
      this.$emit("page-loading", false);
    },
    setHideColumns(columns) {
      // 供应商报价权限控制Price
      let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode("NoSeeSupplier");
      let hideCols = ",SupplierName,Contact,Telephone,Email,Address,QQ,Mobile,";

      _.remove(columns, item => {
        if (!viewBuyPrice && item.BindField === "Price") {
          return true;
        }
        if (noSeeSupplier && hideCols.indexOf(item.BindField) > 0) {
          return true;
        }
      });
    },
    onSupplierNameCellClick(e) {
      this.onInquiry(e.row);
    },
    onInquiry: async function (data) {
      // 询价
      this.$nextTick(function () {
        this.stkInquiry.SupplierName = data.SupplierName;
        this.stkInquiry.Model = data.Model;
        this.stkInquiry.Brand = data.Brand;
        this.stkInquiry.Packaging = data.Packaging;
        this.stkInquiry.MakeYear = data.MakeYear;
        this.stkInquiry.Quality = data.Quality;
        this.stkInquiry.InvQty = data.InvQty;
        this.stkInquiry.MPQ = data.MPQ;
        this.stkInquiry.Qty = data.InvQty;
        this.stkInquiry.Price = data.BuyPrice;
        this.addInquire(this.stkInquiry, function (msg) {
          if (msg === "不能向自己询价") {
            this.$message({message: msg, type: "warning"});
          }
        });
      });
    },
    hasRes(code) {
      return _.some(this.resourceList, page => page.Code === code);
    },
    onAdoptEnd(stkGUID) {
      // 采纳结束后前台显示值改变
      this.searchModel.IQGUID = stkGUID;
    },
    onQuotedPrice(rows) {
      if (rows !== null && rows.length > 0) {
        this.bomMasterDetailEditVisibleTwo = true;
        this.$nextTick(() => {
          this.bomMasterDetail = rows;
        });
      } else {
        this.$emit("QuotePriceDetial", rows);
      }
    },

    onAddStkOut(rows) {
      let order = {RFQ_InquireModel: [rows]};
      this.$store.commit("setParamOrder", order);
      this.$nextTick(function () {
        this.stkOutEditVisible = true; // 显示编辑页面
      });
    },
    onStkInadd(rows) {
      // 采购入库传值方法
      // console.log(rows, 'rows')
      let order = {RFQ_InquireModel: [rows]};
      this.$store.commit("setParamOrder", order);
      // console.log(order, 'order')
      this.$nextTick(function () {
        this.stkInEditVisible = true; // 显示编辑页面
      });
    },
    onBomAdd(rows) {
      this.$emit("QuotePriceDetial", rows);
    },
    StockVMIAdd(rows) {
      return this.$post(CONFIG.StockVMIAdd, rows, data => {
        if (data === undefined || data === "") {
          this.$message({message: "保存失败", type: "error"});
        } else {
          this.$message({message: "保存成功", type: "success"});
          this.onBindData(this.pageIndex);
        }
      });
    },

    stkInAddRows() {
      // 批量采购入库
      let rows = this.onSelectRow(); // 选中行的bdlineguid数组
      if (rows.length > 0) {
        let order = {InquireModel: rows};
        this.$store.commit("setParamOrder", order);
        this.$nextTick(function () {
          this.stkInEditVisible = true; // 显示编辑页面
        });
      }
    },
    stkOutAddRows() {
      // 批量销售出库
      let rows = this.onSelectRow(); // 选中行的bdlineguid数组
      if (rows.length > 0) {
        let order = {InquireModel: rows};
        this.$store.commit("setParamOrder", order);
        this.$store.state.visibled = false;
        this.$nextTick(function () {
          this.stkOutEditVisible = true;
        });
      }
    },
    onBomAddRows() {
      // 批量报价
      let rows = this.onSelectRow(); // 选中行的bdlineguid数组
      if (rows.length > 0) {
        let order = {InquireModel: rows};
        this.$store.commit("setParamOrder", order);
        this.$nextTick(function () {
          this.bomMasterDetailEditVisible = true; // 显示编辑页面
        });
      }
    },
    stockVMIAddList(row = null) {
      // 批量添加
      let rows = [];
      if (row) {
        rows = [];
        rows.push(row);
      } else {
        rows = this.$refs.flexGrid.getSelectedRows();
      }
      if (_.isUndefined(rows[0])) {
        if (!this.CurrentItem || this.CurrentItem.BDLineGUID) {
          // 选中行
          return this.$message({
            message: "请至少选择一条数据进行操作",
            type: "warning"
          });
        }
      }
      if (rows.length > 0) {
        var isDiff = true;
        _.each(rows, r => {
          if (!r.IsAddToVM) {
            isDiff = false;
          }
        });
        if (isDiff) {
          return this.$message({
            message: "该型号已经添加到供应商库存",
            type: "warning"
          });
        } else {
          this.StockVMIAdd(rows);
        }
      }
      // row['AddToVMInquire']
    },
    onSelectRow() {
      // let guid = [] // 选中行的bdlineguid数组
      let rows = this.$refs.flexGrid.getSelectedRows();
      if (_.isUndefined(rows[0])) {
        if (!this.CurrentItem) {
          // 选中行
          return this.$message({
            message: "请至少选择一条数据进行操作",
            type: "warning"
          });
        }
      } else {
        // 判断选中行的客户是否一致
        var lastCustomerName = rows[0].SupplierName;
        var isDiff = false;
        _.each(rows, r => {
          if (lastCustomerName !== r.SupplierName) {
            isDiff = true;
          }
        });
        if (isDiff) {
          return this.$message({
            message: "请选择相同供应商的数据进行操作",
            type: "warning"
          });
        }
        // guid = _.map(rows, item => {
        //   return item.BDLineGUID
        // })
      }
      return rows;
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true;
      let guid = null;
      if (this.sourceType === 1) {
        guid = this.searchModel.BDLineGUID;
      } else if (this.sourceType === 2) {
        guid = this.searchModel.ASGUID;
      } else {
        guid = this.searchModel.PNGUID;
      }
      let filterLast = _.extend({}, this.fieldsFilter, {
        Brand: this.searchModel.Brand,
        Packaging: this.searchModel.Packaging,
        CustomerName: this.searchModel.CustomerName
      });
      const {Keywords, KeywordsField} = this.searchModel;
      if (Keywords && KeywordsField) {
        filterLast[KeywordsField] = Keywords;
      }

      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(filterLast),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          Model: this.inputModel,
          IsAnalyzer: false,
          SourceType: this.sourceType,
          // 需求来源GUID  1.需求明细 2.询价通知明细 3.采购通知明细
          SourceGUID: guid
        }
      );

      return await this.$post(CONFIG.searchURL, data, data => {
        _.each(data.ResultList, item => {
          // 给AdoptQty赋初始值 避免采纳时获取不到采纳数量 。
          if (item.AdoptQty === undefined) {
            item.AdoptQty = null;
          }
        });
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        this.$emit("set-total-count", this.totalCount);
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
        this.$emit("page-loading", false);
        this.$emit("grid-show", true);
      });
    },
    async onHeadSearch(param = {}) {
      this.fieldsFilter.MakeYear = param.MakeYear || "";
      this.fieldsFilter.Brand = param.Brand || "";
      this.fieldsFilter.Packaging = param.Packaging || "";
      this.fieldsFilter.Model = param.Model;
      if (param.CCompanyID) {
        this.fieldsFilter.CCompanyID = param.CCompanyID;
      }
      if (param.SupplierName) {
        this.fieldsFilter.SupplierName = param.SupplierName;
      }

      this.searchModel.Model = param.Model || "";
      this.searchModel.Brand = param.Brand || "";
      this.searchModel.Packaging = param.Packaging || "";
      this.searchModel.IsZero = param.IsZero || false;
      this.searchModel.CustomerName = param.CustomerName || "";
      this.searchModel.Keywords = param.Keywords || "";
      this.searchModel.KeywordsField = param.KeywordsField || "";

      await this.onBindData(1);
    },
    onSearch(params) {
      // // debugger
      // 要设置为null,原因:转成 FieldWhereString 里面会带上 ASGUID,BDLineGUID的值
      // 防止后台拿到这两个GUID的值 作为查询条件
      // params.ASGUID = null
      // params.BDLineGUID = null
      // console.log('params', params)
      if (this.hasValue(params)) {
        this.IQGUID = params.IQGUID || null;
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      return this.onBindData(1);
    },
    async OnAdoptPrice() {
      let rows = this.$refs.flexGrid.getChangedData();
      this.IsSingle = false;
      this.onSaveRows(rows);
    },
    async onSaveRows(rows) {
      let isDiff = false;
      let msg = "";
      let diffMode = "";
      _.each(rows, item => {
        if (item.AdoptQty < 0) {
          msg = "采纳数量不能小于0";
          return;
        } else {
          msg = "";
        }
        if (item.Model !== this.searchModel.Model) {
          diffMode += item.Model + ",";
          isDiff = true;
        }
      });
      if (this.hasValue(msg)) {
        return this.$message({message: msg, type: "warning"});
      }
      let text =
        "采纳型号" +
        diffMode +
        "与需求型号" +
        this.searchModel.Model +
        "不一致，是否继续采纳?";
      if (isDiff) {
        await this.$confirm(text, "采纳提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          return this.onSaveAdopt(rows);
        });
      } else {
        return await this.onSaveAdopt(rows);
      }
    },
    onSaveAdopt(rows) {
      let info = _.find(rows, item => {
        return !this.hasValue(item.Price) || item.Price === 0;
      });
      if (info) {
        this.$message({
          message: "型号:" + info.Model + " 供应商报价单价不能为空",
          type: "warning"
        });
        return;
      }
      if (this.IsSingle) {
        // 单条采纳
        if (rows[0].Qty >= this.searchModel.NeedAdoptQty) {
          rows[0].AdoptQty = this.searchModel.NeedAdoptQty;
        } else {
          rows[0].AdoptQty = rows[0].Qty;
        }
      }
      info = _.find(rows, item => {
        return !this.hasValue(item.AdoptQty) || item.AdoptQty <= 0;
      });
      if (info) {
        return this.$message({
          message: "型号:" + info.Model + " 采纳量不能为空",
          type: "warning"
        });
      }
      _.each(rows, item => {
        item.BDLineGUID = this.searchModel.BDLineGUID;
        item.ASGUID = this.searchModel.ASGUID;
        item.PNGUID = this.searchModel.PNGUID;
      });
      if (this.IsSingle) {
        this.onSaveBySingle(rows[0]);
      } else {
        this.onSaveByAdoptQty(rows);
      }
    },
    // 按采纳量采纳
    async onSaveByAdoptQty(rows) {
      if (rows.length > 0) {
        let array = [];
        _.each(rows, item => {
          let info = {
            CurrencyCode: item.CurrencyCode,
            Qty: item.AdoptQty,
            Price: item.Price,
            IAGUID: item.IAGUID,
            IQGUID: item.IQGUID,
            PNGUID: item.PNGUID,
            Packaging: item.Packaging,
            MPQ: item.MPQ,
            MakeYear: item.MakeYear,
            Quality: item.Quality,
            DeliveryDate: item.DeliveryDate,
            ASGUID: item.ASGUID || null,
            BDLineGUID: item.BDLineGUID,
            Model: item.Model,
            Brand: item.Brand,
            TaxRate: item.TaxRate,
            BomQuoteType: this.bomQuoteType,
            PurGrossRate: this.purGrossRate,
            IsSingle: item.IsSingle
          };
          array.push(info);
        });
        this.loading = true;
        return await this.$post(CONFIG.saveURL, array, (datas, logic) => {
          if (logic.code === 200) {
            this.$emit("save-success", datas);
            this.$message({message: "保存成功", type: "success"});
          } else {
            this.$message({message: logic.msg, type: "error"});
          }
          this.onRefresh();
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    // 按单条询价
    async onSaveBySingle(row) {
      let info = {
        CurrencyCode: row.CurrencyCode,
        Qty: row.AdoptQty,
        Price: row.Price,
        IQGUID: row.IQGUID,
        PNGUID: row.PNGUID,
        Packaging: row.Packaging,
        MPQ: row.MPQ,
        MakeYear: row.MakeYear,
        Quality: row.Quality,
        DeliveryDate: row.DeliveryDate,
        ASGUID: row.ASGUID || null,
        BDLineGUID: row.BDLineGUID,
        Model: row.Model,
        Brand: row.Brand,
        TaxRate: row.TaxRate,
        BomQuoteType: this.bomQuoteType,
        PurGrossRate: this.purGrossRate,
        IsSingle: true
      };

      this.loading = true;
      return await this.$post(CONFIG.saveSingleURL, info, (datas, logic) => {
        if (logic.code === 200) {
          this.$emit("save-success", datas);
          this.$message({message: "保存成功", type: "success"});
        } else {
          this.$message({message: logic.msg, type: "error"});
        }
        this.onRefresh();
      }).finally(() => {
        this.loading = false;
      });
    },
    async onRefresh() {
      await this.onBindData(this.pageIndex);
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StkInquireRecord", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("StkInquireRecord", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("StkInquireRecord", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StkInquireRecord");
    },

    // 生成采购订单
    onOrdpurchase(inquire) {
      // 生成采购单
      this.$store.state.visibled = false;
      // console.log(inquire, 'inquire')
      let paramOrder = {InquireModel: inquire};
      this.$store.commit("setParamOrder", paramOrder);
      this.$nextTick(function () {
        // this.$refs.table.clearSelection()
        this.editType = "Edit";
        this.ordEditTitle = "新增采购单";
        this.ordEditVisible = true;
      });
      // this.OrdPurchaseOrderAddVisible = true
      // this.IsRefreshByInquire = !this.IsRefreshByInquire
    }
  }
};
</script>

<style lang="scss">
.floatright {
  float: right;
}

.currentInquireClass {
  .znl-tablecell {
    color: red;
  }
}
</style>
